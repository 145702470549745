.ReportFormBox {
  margin-top: 24px;
  flex-direction: column;
  align-items: flex-start !important;
  
}
.ReportFormTitle {
  margin-bottom: 16px;
  text-transform: uppercase;
  
}

.MapBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 300px;
  background-color: #133a56;
  border: 1px solid #0000;
  padding: 4px;
}

.CustomSelect {
  width: 100%;
  height: 48px;
  display: flex;
  text-align: center;
  font-family: "Inter";
  font-weight: 500;
  align-items: center;
  justify-content: space-around;
  margin:  10px 0;
  padding-top: 8px;
  padding-bottom: 8px;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  /* background:  #2196F3; */
  border: 1px solid #d2d2d2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  color: #ffff;
  box-sizing: border-box;
    
}
.CustomSelect:hover {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.3);
}

.SelectModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 70%;
  background-color: #B9B9B9;
}

.BombTypeImg {
  cursor: pointer;
  width: 200px;
  min-height: 200px;
  border: 4px solid grey;
  object-fit: contain;
}
.SubmitedReporttModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  flex-direction: column;
  text-align: center;
  max-width: 480px;
  min-width: 320px;
  background-color: #333537;
  padding: 2rem;
  box-shadow: 24px;
  border: 2px solid #0000;
}

.UploadButton {
  /* height: 100% !important; */
  width: 50%;
  padding: 8px !important;
  border: 1px solid #d2d2d2 !important;
  cursor: pointer;
  transition: 0.5s ease 0s !important;
  display: flex;
  align-items: center;
 }

.UploadButton .css-1d6wzja-MuiButton-startIcon {
  margin-right: 0; /* Забираємо правий margin */
  margin-left: 0;
  padding: 8px !important; /* Забираємо лівий margin */
}

.UploadButton:hover {
  box-shadow: inset 2px 2px 10px -5px rgba(0, 0, 0, 0.75);
}

.UploadInput {
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
}



.UploadError {
  position: absolute;
  top: 100%;
  margin-left: 14px;
  margin-top: 4px;
  color: #d32f2f;
  font-size: 0.75rem;
}