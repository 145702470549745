.AlertWrapper {
  display: block !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 310px;
  max-width: 420px;
  border-radius: 4px;
  background-color: #333537;
  padding: 32px 24px;

  }

.ButtonWrapper {
  display: flex;
  justify-content: center;
  }

  /* @media screen and (min-width: 640px) {
    .AlertWrapper {
      width: 400px;
    }
    .AlertTitle {
      font-size: 14px;
    }
  }   */
