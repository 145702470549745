.EditAccountInfoBox {
  display: block !important;
  padding: 1rem 2rem;
  background-color: #1D1D1D;
  margin-bottom: 2rem;
  }
.MyReportsBox {
  display: block !important;
  width: 100%;
  min-width: 320px;
  background-color: #1D1D1D;
  margin-bottom: 16px;
  padding: 1rem ;
}

.MyReportsItem {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center ;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  text-wrap: wrap;
  
}
.MyReportsCell {
  border: 0;
  padding: 0.5rem !important;
  font-size: 12px;
 }
 @media screen and (max-width: 1360px) {
  .MyReportsCell {
  
    font-size: 10px !important;
   }
} 
.DelMyReportModalBox {
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2d2d2d;
  padding: 1.5rem;
  outline: none;
  display: block !important;
}
