.footerMainBox {
  background-color: #101010;
  margin-top: 4rem;
}

.footerGrid {
  text-align: center;
  padding: 1rem;
}

.navLink {
  display: inline-block;
  text-decoration: none;
  color: #d2d2d2;
  font-family: Montserrat;
  font-size: 14px;
  /* color: #03afff; */
};
