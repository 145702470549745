.MainBox {
  flex-direction: column;
  text-align: center;
  display: inline;
  width: 100%;
  padding: 2;
}

.MainDescBox {
  flex-direction: column;
  /* justify-content: space-between; */
  margin: 8px 0 32px 0;
}

.MainJoinBtn {
  text-align: center;
  max-width: 762px;
}
.MainMapNotLoggBox {
  display: block !important;
  width: 100%;
  height: 444px;
  background-color: #1d1d1d;
  margin-bottom: 32px;
}
.MainMapRecentActivityTitle {
  
  padding: 16px;
  font-weight: 600 !important;
  
}

.UserMainBox {
  width: 100%;
  height: 60px;
  background: #1d1d1d;
  justify-content: space-between;
  padding: 20px;
  margin: 32px 0 32px 0;
  font-family: Montserrat;
  font-size: 14px
}

.UserMainReportBox {
  width: 100%;
  background: #1d1d1d;
  padding: 20px;
  margin-bottom: 32px;
  flex-direction: column;
  align-items: start !important;
}
.UserMainReportButtonsBox {
  width: 100%;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}

.UserMainContectsBox {
  display: flex;
  width: 100%;
  min-width: 320px;
  justify-content: space-between;
  margin-bottom: 4px;
 

  & hr {
    margin: 4px;
  }
}

.SocialMediaBox {
  flex-direction: column;
  width: 100%;
  background: #1d1d1d;
  min-height: 400px;
  height: 100%;
  width: 100%;
  padding: 24px 0 0 0;
  text-align: left !important;
}

.SocialMediaBox h2 {
  width: 90%;

}

.AdsBox {
  width: 100%;
  background: #545252;
  height: 300px;
  padding: 16px;
  display: block;
  background-color: 333537;
}
