.SideBarWrapper {
  position: absolute;
  top: 72px;
  border-radius: 0 !important;
  width: 100%;
  max-width: 420px;
  min-width: 320px;
  display: block !important;
  /* text-transform: uppercase; */
  background-color: rgb(16, 16, 16);
  font-style: #B9B9B9;
  padding:  16px 0;
  box-shadow:  4px 4px 4px rgba(0, 0, 0, 0.2);
}

.listItem {
  padding: 0 16px  !important;
}
