.mainReportsBox {
    display: block !important;
    background-color: #1d1d1d;
    margin-bottom: 1rem;
    padding: 1rem;
    width: 100%;
    min-height: 240px;
}

.itemReportBox {
    justify-content: space-between;   
}

.itemReportText {
    font-size: 14px !important;
    border: none !important;
    padding: 8px !important;
    text-align:  left;
}

