.MakeReportPageBox {
  display: block !important;
  width: 100%;
  max-width: 768px;
  padding: 24px;
  /* margin: auto; */
  background-color: #1d1d1d;
}

@media only screen and (max-width: 1279px) {
  .MakeReportPageBox {
      padding: 16px;
  }
}

/* Для TABLET */
@media only screen and (max-width:899px) {
  .MakeReportPageBox {
      padding: 8px;
      margin-left: auto;
      margin-right: auto;
  }
}

/* Для PHONE */
@media only screen and (max-width: 339px) {
  .MakeReportPageBox {
      padding: 0;
  }
}

.ReportPageMapModal {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: 70vh;
  background-color: #333537;
  padding: 4px;
  box-shadow: 24px;
  border: 1px solid #000;
  
}
