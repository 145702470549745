.StyledToolbar {
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 32px;
    height: 360px;
    
    @media screen and (min-width: 600px) {
      min-height: 470px,
    }
  }
  .LogoTitile {
    font-family: Ethnocentric;
    color: #03afff;
    font-size: 1.5rem;
    line-height: 40px;
  }
  
  .NavPagesLink {
    display: inline-block;
    line-height: 1.2;
    text-decoration: none;
    cursor: pointer;
    margin: 1rem;
  
    color: #ffff;
  
    :after {
      background-color: #03afff;
      display: block;
      content: '';
      height: 2px;
      width: 0%;
      -webkit-transition: width 0.5s ease-in-out;
      -moz--transition: width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out;
    }
    :hover:after,
    :focus:after {
      width: 100%;
    }
  }
  
  .SwichLangBox {
    width: 72px;
    justify-content: center;
    padding: 8px;
    margin-right: 16px;
  }

  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    display: none;
  }