.mapMainMapBox {
  display: inline !important;
  width: 100%;
  background-color: #1d1d1d;
 
}

.containerStyle  {
  width: 100%;
  height: 100%;
  min-height: 444px;
  max-height: 900px;
  border-radius: 8px;
  justify-content: center;
};


.mapMapNavBox {
  width: 100%;
  align-items: normal;
  padding: 16px !important;
  font-weight: 600;
}

.mapMapHazardCollapse {
  position: absolute;
  top: 100%;
  left: center;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  max-width: 260px;
  width: 100%;
}


.mapMapHazardList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}


.mapMapHazardListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
}

.mapMapHazardListItemText {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  vertical-align: middle;
}


.mapMapBox {
  border-radius: 0 0 4px 4px;
  height: 100%;
  width: 100%;
  align-items: normal !important;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  position: relative;
  display: block;
}


.mapContainer {
  flex-grow: 1; /* Збільшується, щоб заповнити залишковий простір */
  position: relative;
  border-radius: 0 0 4px 4px;
}



.mapMapLocalInputBox {
  z-index: 9;
  max-width: 305px;
  background-color: #333537;
  display: block;
  border-radius: 32px !important;
  margin: 8px;
}


.mapMapWrapper {

  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  height: 480px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  margin: 0 auto;
}


.mapLocalInput {
  height: 60px;
  max-width: 100% !important;
  padding: 4px 4px 4px 20px;
  background-color: #333537;
  border-top: none;
  font-size: large;
  border-radius: 32px !important;
}

.mapInputBox {
  width: 100% !important;
}


.mapMarkerImg {
  width: 100%;
  height: 120px;
  margin: 4px auto;
  border-radius: 8px;
  cursor: pointer;
}

.gm-style .gm-style-iw {
  background-color: #D9D9D9 !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 4px !important;
  /* max-width: 240px;
  width: 240px; */
}
.gm-style .gm-style-iw-c {
  background-color: #D9D9D9 !important;
  border: none !important;
  padding: 32px 12px 12px 12px !important;
  border-radius: 4px !important;
  max-width: 320px !important;
  min-width: 280px !important;
 
}
.gm-style .gm-style-iw-chr {
  position: absolute;
  right: 0;
  top: 0;
  
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-tc::after {
  background-color: #D9D9D9 !important;
  border-color: #D9D9D9 !important;
}

/* .gm-style .gm-style-iw-tc {
  background-color: #D9D9D9 !important;
} */
